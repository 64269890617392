<template>
	<div class="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
		<div class="max-w-md w-full space-y-8">
			<div>
				<div class="text-center text-gray-900">
					<div class="flex flex-col justify-center items-center px-2">
						<div class="mb-3 text-3xl font-extrabold">Foresight SI</div>
						<div class="mb-5 text-lg tracking-widest">Welcome!</div>
						<div class="text-sm font-bold tracking-wide">We just need to confirm a few details</div>
					</div>
				</div>
			</div>
			<div class="mt-8 space-y-6 border-0 bg-transparent">
				<div>
					<div class="mt-3">
						<label for="email-address">Your email address</label>
						<input
							id="email-address"
							name="email"
							type="email"
							autocomplete="email"
							class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
							v-model="auth.user.email"
							readonly />
					</div>
					<div class="mt-5">
						<label for="name">Your name</label>
						<input
							id="name"
							name="name"
							type="text"
							autocomplete="current-password"
							class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
							placeholder="name"
							v-model="name"
							required />
					</div>
				</div>

				<div>
					<v-button
						@click="register"
						:loading="registering"
						class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						Complete registration
					</v-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/ui/Button";

export default {
	mounted() {
		this.checkUser();
		this.name = this.auth.user.firstName + " " + this.auth.user.lastName;
	},

	data() {
		return {
			name: null,
			registering: false,
		};
	},

	components: {
		"v-button": Button,
	},

	props: {},

	methods: {
		async checkUser() {
			try {
				let response = await this.$api.resource("users").fetchOne(this.auth.user.email);
				this.$router.push({
					name: "user-home",
				});
			} catch (e) {
				// console.log(`Blablah: ${e}`);
			}
		},
		async register() {
			this.registering = true;
			try {
				let response = await this.$api.resource("userRegister").create({ name: this.name });
				// Force a complete reload to ensure user is fully loaded from the teams API
				window.location.reload();
			} catch (e) {
				// console.log(`Blablah: ${e}`);
				this.registering = false;
			}
		},
	},

	computed: {
		...mapGetters(["auth"]),
	},
};
</script>
<style></style>
