<template>
	<UserRegister />
</template>

<script>
import UserRegister from "@/components/UserRegister";
import { mapGetters } from "vuex";

export default {
	components: {
		UserRegister,
	},
};
</script>

<style></style>
